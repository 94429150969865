export function fileWindowApi(onUpload) {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    document.body.appendChild(fileInput);
    fileInput.addEventListener("change", (event) => {
        const file = event.target.files[0];
        toBase64(file).then((base64) => {
            onUpload({
                file,
                base64,
            });
        });
    });
    fileInput.click();
}
const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
    });
};
