var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observe"},[_c('b-card',{staticClass:"p-2",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-between"},[_c('div',[_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteAd(_vm.advertDto.id)}}},[_vm._v("حذف")])],1),_c('div',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("تعديل ")]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-primary "},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("تراجع ")])],1)])]},proxy:true}])},[_c('b-card-header',{attrs:{"header-class":"p-0 mb-2"}},[_c('div',{staticClass:"d-flex"},[_c('unicon',{attrs:{"name":"megaphone","width":"20","height":"20","fill":"#882ff6"}}),_c('h4',{staticClass:"ml-1 mb-0"},[_vm._v("تفاصيل الإعلان")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex"},[_vm._v("حالة الإعلان:")]),_c('StatusBadge',{staticClass:"ml-1",attrs:{"options":_vm.statusAdvertList,"value":_vm.advertDto.statusAds}})],1)]),_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('ek-date-picker',{attrs:{"range":"","rules":[
              { type: 'required', message: 'تاريخ الإعلان مطلوب' },
              {
                type: 'min:15',
                message: 'يرجى إدخال تاريخ بداية و تاريخ نهاية',
              } ],"label":"تاريخ الإعلان","name":"adsDate"},on:{"input":function($event){return _vm.updateAdsDate($event)}},model:{value:(_vm.advertDto.adsDatesList),callback:function ($$v) {_vm.$set(_vm.advertDto, "adsDatesList", $$v)},expression:"advertDto.adsDatesList"}}),_c('ek-input-text',{attrs:{"rules":[{ type: 'required', message: 'عنوان الإعلان' }],"label":"عنوان الإعلان","placeholder":"ادخل عنوان الإعلان","name":"adTitle"},model:{value:(_vm.advertDto.titl),callback:function ($$v) {_vm.$set(_vm.advertDto, "titl", $$v)},expression:"advertDto.titl"}}),_c('ek-input-textarea',{attrs:{"rules":[
              {
                type: 'required',
                message: 'النص مطلوب',
              } ],"label":"النص","placeholder":"ادخل النص","name":"description"},model:{value:(_vm.advertDto.content),callback:function ($$v) {_vm.$set(_vm.advertDto, "content", $$v)},expression:"advertDto.content"}})],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.advertDto.mediaType == 2)?_c('ek-input-image',{attrs:{"rules":[{ type: 'required', message: ' الصورة مطلوبة ' }],"name":"الصورة","placeholder":"أدخل الصورة","label":" الصورة","image":_vm.advertDto.url},on:{"input":_vm.updateAdsImg}},[_c('h5',[_vm._v("انقر لتحميل الصورة او اسحب الملف")])]):_vm._e(),(_vm.advertDto.mediaType == 1)?_c('label',[_vm._v("الفيديو الحالي")]):_vm._e(),_c('b-dropdown',{staticClass:"position-absolute",staticStyle:{"z-index":"999"},attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"rounded-circle"},[_c('unicon',{attrs:{"name":"ellipsis-v","fill":"#fff"}})],1)]},proxy:true}])},[_c('b-dropdown-item',{ref:"myUploader",attrs:{"href":"#"},on:{"click":function($event){return _vm.fileWindowApi(_vm.storeFile)}}},[_c('unicon',{staticStyle:{"width":"25px"},attrs:{"name":"edit-alt","fill":"#882FF6"}}),_c('span',[_vm._v("تعديل الفيديو")])],1)],1),(_vm.advertDto.mediaType == 1)?_c('video',{staticClass:"w-100",attrs:{"autoplay":"","controls":"","src":_vm.advertDto.url}}):_vm._e(),(_vm.advertDto.mediaType == 1)?_c('ek-input-image',{attrs:{"rules":[{ type: 'required', message: ' غلاف الفيديو مطلوب' }],"name":"غلاف الفيديو","placeholder":"أدخل غلاف الفيديو","label":"غلاف الفيديو","image":_vm.advertDto.thumbUrl},on:{"input":_vm.updateAd}},[_c('h5',[_vm._v("انقر لتحميل الصورة او اسحب الملف")])]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }