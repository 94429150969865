<template>
  <ValidationObserver ref="observe">
    <b-card class="p-2">
      <b-card-header header-class="p-0 mb-2">
        <div class="d-flex">
          <unicon name="megaphone" width="20" height="20" fill="#882ff6" />
          <h4 class="ml-1 mb-0">تفاصيل الإعلان</h4>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex">حالة الإعلان:</div>
          <StatusBadge
            class="ml-1"
            :options="statusAdvertList"
            :value="advertDto.statusAds"
          />
        </div>
      </b-card-header>

      <b-form class="mt-1">
        <b-row>
          <b-col cols="6">
            <ek-date-picker
              range
              :rules="[
                { type: 'required', message: 'تاريخ الإعلان مطلوب' },
                {
                  type: 'min:15',
                  message: 'يرجى إدخال تاريخ بداية و تاريخ نهاية',
                },
              ]"
              label="تاريخ الإعلان"
              name="adsDate"
              v-model="advertDto.adsDatesList"
              @input="updateAdsDate($event)"
            />

            <ek-input-text
              :rules="[{ type: 'required', message: 'عنوان الإعلان' }]"
              label="عنوان الإعلان"
              placeholder="ادخل عنوان الإعلان"
              name="adTitle"
              v-model="advertDto.titl"
            />

            <ek-input-textarea
              :rules="[
                {
                  type: 'required',
                  message: 'النص مطلوب',
                },
              ]"
              label="النص"
              placeholder="ادخل النص"
              name="description"
              v-model="advertDto.content"
            />
          </b-col>
          <b-col md="6">
            <ek-input-image
              v-if="advertDto.mediaType == 2"
              :rules="[{ type: 'required', message: ' الصورة مطلوبة ' }]"
              name="الصورة"
              placeholder="أدخل الصورة"
              label=" الصورة"
              :image="advertDto.url"
              @input="updateAdsImg"
            >
              <h5>انقر لتحميل الصورة او اسحب الملف</h5>
            </ek-input-image>

            <label v-if="advertDto.mediaType == 1">الفيديو الحالي</label>

            <b-dropdown
              size="lg"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="position-absolute"
              style="z-index: 999"
            >
              <template #button-content>
                <div class="rounded-circle">
                  <unicon name="ellipsis-v" fill="#fff"></unicon>
                </div>
              </template>
              <b-dropdown-item
                href="#"
                ref="myUploader"
                @click="fileWindowApi(storeFile)"
              >
                <unicon name="edit-alt" fill="#882FF6" style="width: 25px"></unicon>
                <span>تعديل الفيديو</span>
              </b-dropdown-item>
            </b-dropdown>
            <video
              v-if="advertDto.mediaType == 1"
              autoplay
              controls
              class="w-100"
              :src="advertDto.url"
            ></video>

            <ek-input-image
              v-if="advertDto.mediaType == 1"
              :rules="[{ type: 'required', message: ' غلاف الفيديو مطلوب' }]"
              name="غلاف الفيديو"
              placeholder="أدخل غلاف الفيديو"
              label="غلاف الفيديو"
              :image="advertDto.thumbUrl"
              @input="updateAd"
            >
              <h5>انقر لتحميل الصورة او اسحب الملف</h5>
            </ek-input-image>
          </b-col>
        </b-row>
      </b-form>
      <template #footer>
        <div class="w-100 d-flex justify-content-between">
          <div>
            <b-button
              variant="outline-danger"
              class="mx-1"
              @click="deleteAd(advertDto.id)"
              >حذف</b-button
            >
          </div>
          <div>
            <b-button variant="primary" @click="submit">تعديل </b-button>
            <b-button variant="outline-primary " class="mx-1" @click="$router.go(-1)"
              >تراجع
            </b-button>
          </div>
        </div>
      </template>
    </b-card>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";
import { fileWindowApi } from "@core/utils/uploader";

export default {
  props: {
    id: String,
  },
  components: {
    StatusBadge,
  },
  computed: {
    ...mapState({
      statusAdvertList: ({ adverts }) => adverts.statusAdvertList,
      advertDto: (state) => state.adverts.advertDto,
    }),
    ...mapGetters(["adsStatusName", "adsIsActive", "adsIsExpired"]),
  },
  data: () => ({}),
  methods: {
    ...mapActions(["getAdsDetails", "updateAds", "deleteAd"]),
    ...mapMutations(["Update_Ads_Dto_Date"]),
    deletedMedias(target, index) {
      console.log("delete id", index);
      target.deletedMedias.push(index);
    },
    fileWindowApi,
    storeFile(e) {
      this.advertDto.file = e.file;
    },
    submit() {
      this.$refs.observe.validate().then((success) => {
        if (success) {
          if (this.advertDto.mediaType == 1) {
            this.updateAds(this.advertDto);
          } else if (this.advertDto.mediaType == 2) {
            this.updateAds(this.advertDto);
          }
          this.$refs.observe.reset();
        }
      });
    },
    updateAdsDate($event) {
      this.Update_Ads_Dto_Date($event);
    },
    updateAdsImg({ file }) {
      this.advertDto.file = file;
    },
    updateAd({ file }) {
      this.advertDto.thumbFile = file;
    },
  },
  created() {
    this.getAdsDetails(this.id);
  },
};
</script>
<style lang="scss">
.dropdown.b-dropdown {
  right: 0 !important;
  top: 2% !important;
}
</style>
